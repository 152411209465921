import { Button, Icon } from '@chakra-ui/react';
import { HiOutlineArrowDownTray } from 'react-icons/hi2';

export const DownloadCSV = ({ url, isLoading, isError }: { url?: string; isLoading: boolean; isError?: any }) => {
  return (
    <Button
      as="a"
      href={url}
      isDisabled={isError}
      visibility={url ? 'visible' : 'hidden'}
      size={{ base: 'sm', md: 'md' }}
      leftIcon={<Icon as={HiOutlineArrowDownTray} />}
      isLoading={isLoading}
    >
      Download CSV
    </Button>
  );
};
