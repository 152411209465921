import React, { useRef } from 'react';
import * as htmlToImage from 'html-to-image';
import QRCode from 'react-qr-code';
import jsPDF from 'jspdf';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { ButtonVariant } from 'src/themes/ButtonVariants';

export function QRCodeModal(props: {
  disclosure: ReturnType<typeof useDisclosure>;
  disableClose?: boolean;
  taskId?: string;
  taskName?: string;
}) {
  const { disclosure, taskId, taskName } = props;
  const generateQRModal = useDisclosure();
  const qrCodeRef = useRef(null);
  const downloadQRCode = () => {
    if (qrCodeRef.current) {
      htmlToImage.toPng(qrCodeRef.current).then((dataUrl) => {
        const doc = new jsPDF();
        const pageWidth = doc.internal.pageSize.width;
        const pageHeight = doc.internal.pageSize.height;
        const qrWidth = 100;
        const qrHeight = 100;
        const x = (pageWidth - qrWidth) / 2;
        const y = (pageHeight - qrHeight) / 2;
        doc.addImage(dataUrl, 'PNG', x, y, qrWidth, qrHeight);
        doc.save(`Task_${taskName}_QRcode.pdf`);
      });
    }
  };
  return (
    <>
      <Modal
        isOpen={disclosure.isOpen !== generateQRModal.isOpen}
        onClose={disclosure.onClose}
        size={{ sm: 'md', md: 'lg', xl: 'xl' }}
        closeOnEsc={!props.disableClose}
        closeOnOverlayClick={!props.disableClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center">Scan the QR below</ModalHeader>
          <ModalCloseButton top="1rem" display={props.disableClose ? 'none' : undefined} />
          <ModalBody display="flex" justifyContent="center" alignItems="center" mt="0.75rem">
            <div ref={qrCodeRef}>
              <QRCode value={JSON.stringify({ taskId: taskId })} size={300} />
            </div>
          </ModalBody>
          <ModalFooter display="flex" justifyContent="center" alignItems="center" mt="0.75rem">
            <Button variant={ButtonVariant.submit} onClick={downloadQRCode}>
              Download QR Code
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
