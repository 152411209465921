import { Box, Button, Container, Flex, Heading, HStack } from '@chakra-ui/react';
import { ServerUserEndpoint } from '@karya/core';
import { Select } from 'chakra-react-select';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Colors,
  Legend,
  LinearScale,
  Tooltip,
} from 'chart.js';
import { useState } from 'react';
import { withProtectedScreen } from 'src/auth/ProtectedScreen';
import { GraphCollection } from 'src/components/Dashboard/GraphCollection';
import { DownloadCSV } from 'src/components/Reports/DownloadReportCSV';
import { reportsApi } from 'src/features/reportsApi';
import { ColorMap } from 'src/themes/Attributes';
import { ButtonVariant } from 'src/themes/ButtonVariants';

ChartJS.register(ArcElement, BarElement, Tooltip, Legend, CategoryScale, LinearScale, Colors);

export const DashboardPagePermissions: ServerUserEndpoint[] = [
  'GET_REPORTS',
  'GET_REPORT_RESULT',
  'GET_REPORT_RESULT_DOWNLOAD_URL',
  'GET_REPORTS_BY_KIND',
  'GET_REPORT_BY_KIND_AND_ID',
  'GET_REPORT_RESULT_DOWNLOAD_URL',
];

const { useGetAllGraphCollectionsQuery, useGetReportDownloadUrlQuery } = reportsApi;

type CollectionOption = {
  label: string;
  value: string;
};

export const Dashboard = withProtectedScreen(() => {
  const { data } = useGetAllGraphCollectionsQuery({ src: 'web' });

  const collectionOptions = data
    ? Object.keys(data).map((collectionId: any) => ({
        label: data[collectionId].name,
        value: collectionId,
      }))
    : [];

  const [selectedCollection, setSelectedCollection] = useState<CollectionOption | null>(null);
  const {
    data: summaryDownloadUrl,
    isLoading: isFetchingUrl,
    isError: isUrlError,
  } = useGetReportDownloadUrlQuery(
    {
      src: 'web',
      id: selectedCollection?.value!,
      kind: 'graphs',
      params: [],
      mediaEnabled: false,
    },
    { skip: selectedCollection == null },
  );

  return (
    <Box width="100%" height="100vh" backgroundColor={ColorMap.white}>
      <Flex direction="column" height="100%">
        <Container p={10} maxW="full">
          <Heading mb={8}>Dashboard</Heading>
          <HStack width={{ base: '100%', md: '60%' }} gap="15px">
            <Box flex={1}>
              <Select
                size={{ base: 'sm', md: 'md' }}
                id="graph-select"
                name="graph-collections"
                placeholder="Select the graph collection to display..."
                closeMenuOnSelect
                options={collectionOptions}
                value={selectedCollection}
                onChange={(value) => {
                  setSelectedCollection(value);
                }}
              />
            </Box>
            <DownloadCSV url={summaryDownloadUrl?.url} isLoading={isFetchingUrl} isError={isUrlError} />
          </HStack>
          {data && selectedCollection ? <GraphCollection collectionId={selectedCollection.value} /> : null}
        </Container>
      </Flex>
    </Box>
  );
}, DashboardPagePermissions);
